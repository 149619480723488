<template>
  <v-container class="pa-0">
    <base-subheading>صفحات تابعة لنا</base-subheading>
    <v-chip
      class="mr-2"
      color="secondary"
      label
      small
      tag
      text-color="white"
      href="https://www.facebook.com/thoughts.team/"
      target="_blank"
    >
      فريق افكار الفني
    </v-chip>
    <v-chip
      class="mr-2"
      color="secondary"
      label
      small
      tag
      text-color="white"
      href="https://www.facebook.com/atabat.news/"
      target="_blank"
    >
      عتبات نيوز
    </v-chip>
    <v-chip
      class="mr-2"
      color="secondary"
      label
      small
      tag
      text-color="white"
      href="https://www.facebook.com/lawcultureg/"
      target="_blank"
    >
      الوقاية القانونية خيرا من العقاب
    </v-chip>
    <v-chip
      class="mr-2"
      color="secondary"
      label
      small
      tag
      text-color="white"
      href="https://www.facebook.com/people/%D8%B7%D8%A8%D9%8A%D8%A8-%D8%A7%D9%84%D8%B9%D8%A7%D8%A6%D9%84%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A/100044720603574/"
      target="_blank"
    >
      طبيب العائلة الالكتروني
    </v-chip>
  </v-container>
</template>

<script>
export default {
  name: "Tags",
  created() {},
  computed: {},
  methods: {},
};
</script>
